import "vite/modulepreload-polyfill";

// import Lenis from "lenis";
import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import intersect from "@alpinejs/intersect";
import collapse from "@alpinejs/collapse";
import cart from "../lib/cart";
import { detectAppleDevice } from "../lib/detect-safari";
import revealer from "../lib/revealer";

const deviceInfo = detectAppleDevice();
// console.log('deviceInfo', deviceInfo)
if (deviceInfo.appleDevice) document.documentElement.classList.add("apple-device");
if (deviceInfo.isIOS) document.documentElement.classList.add("ios");
else {
	// new Lenis({ autoRaf: true, lerp: 0.3 });
}
if (deviceInfo.isIPad) document.documentElement.classList.add("ipad");
if (deviceInfo.iOSVersion) document.documentElement.classList.add(`ios-${deviceInfo.iOSVersion.major}`, `ios-${deviceInfo.iOSVersion.major}.${deviceInfo.iOSVersion.minor}`);

// set up Alpine
window["Alpine"] = Alpine;
// Alpine.plugin(AsyncAlpine);
Alpine.plugin(focus);
Alpine.plugin(intersect);
Alpine.plugin(collapse);
Alpine.store("cart", cart());

// Import all components from the components folder -- vite
const modules = import.meta.glob(["../components/*.ts", "../components/*.js"], { eager: true });

Object.entries(modules).forEach(([path, component]) => {
	const name = path.split("/").pop()?.split(".")[0]; // get the name from the file
	if (name) Alpine.data(name, (component as any).default);
});

// Alpine Async seems to have a bug
// Alpine.asyncData('productExplorer', () => import('../async-components/productExplorer.js') );
revealer();
Alpine.start();
console.log(
	"%c DEVELOPMENT BY CEMENTWORKS",
	"background: linear-gradient(180deg, #4169E1, #0041C2, #FFFFFF); " +
		"color: white; " +
		'font-family: "Helvetica", sans-serif; ' +
		"font-size: 14px; " +
		"font-weight: 900; " +
		"padding: 4px 10px; " +
		"border-radius: 2px; " +
		"letter-spacing: 14px; " +
		"text-shadow: 2px 2px 4px rgba(0,0,0,0.3); " +
		"background-size: 200% 200%;",
);
console.log(
	"%c DESIGN BY CLIO DILL",
	"background: linear-gradient(180deg, #4169E1, #0041C2, #FFFFFF); " +
		"color: white; " +
		'font-family: "Helvetica", sans-serif; ' +
		"font-size: 14px; " +
		"font-weight: 900; " +
		"padding: 4px 10px; " +
		"border-radius: 2px; " +
		"letter-spacing: 14px; " +
		"text-shadow: 2px 2px 4px rgba(0,0,0,0.3); " +
		"background-size: 200% 200%;",
);
