import { haveWorkingHoursElapsed } from "../lib/dates";

const BASE_URL = "https://chomperlabs.ue.r.appspot.com";
// const BASE_URL = " https://cementworks.ngrok.dev";

const SHORT_STATUS_MAP = {
	Cancelled: "Cancelled",
	Incoming: "Incoming",
	"Additional Needed": "Incoming",
	"In Progress": "In the works",
	"In Progress + 12": "In the works",
	"In Progress + 24": "In the works",
	"In Progress + 36": "In the works",
	Shipped: "Shipped",
	Delivered: "Delivered",
};

const LONG_STATUS_MAP = {
	Cancelled: "Your order has been cancelled",
	Incoming: "We're waiting for your impression",
	"Additional Needed": "Looks like you need another try - waiting on your new impression",
	"In Progress": "We have your impression and are checking it over",
	"In Progress + 12": "We're 3D scanning your impression and creating a model of your teeth",
	"In Progress + 24": "We're hand crafting your night guard",
	"In Progress + 36": "We're giving your guard a final polish and quality check",
	Shipped: "Your guard is on its way",
	Delivered: "Great news! Your guard has been delivered",
};

const SIMPLE_SHORT_STATUS_MAP = {
	Cancelled: "Cancelled",
	Confirmed: "Confirmed",
	Shipped: "Shipped",
	Delivered: "Delivered",
};

const SIMPLE_LONG_STATUS_MAP = {
	Cancelled: "Your order has been cancelled",
	Confirmed: "We're working on your order",
	Shipped: "Your order is on its way",
	Delivered: "Great news! Your order has been delivered",
};

export default (opt = {}) => ({
	status: null,
	cancelled: false,
	delivered: false,
	numSteps: 3,
	currentStep: 0,
	containsReorderProducts: null,
	date_shipped: null,
	date_updated: null,
	customerEmail: null,
	shortStatus: null,
	longStatus: null,
	error: false,
	message: null,
	progress: null,
	loading: true,
	...opt,
	async init() {
		if (this.numSteps === 2) {
			await this.getSimpleShipmentStatus();
		}
		if (this.numSteps === 7) {
			await this.getShipmentStatus();
		}
	},
	async getSimpleShipmentStatus() {
		if (this.cancelled) {
			this.currentStep = 0;
			this.shortStatus = SIMPLE_SHORT_STATUS_MAP["Cancelled"];
			this.longStatus = SIMPLE_LONG_STATUS_MAP["Cancelled"];
			this.loading = false;
			return;
		}

		if (this.status === "Complete" || this.fulfillmentStatus === "fulfilled") {
			this.currentStep = 2;
			this.shortStatus = SIMPLE_SHORT_STATUS_MAP["Shipped"];
			this.longStatus = SIMPLE_LONG_STATUS_MAP["Shipped"];
			this.loading = false;
			return;
		}

		this.currentStep = 1;
		this.shortStatus = SIMPLE_SHORT_STATUS_MAP["Confirmed"];
		this.longStatus = SIMPLE_LONG_STATUS_MAP["Confirmed"];
		this.loading = false;
		return;
	},
	async getShipmentStatus() {
		try {
			if (this.cancelled) {
				this.currentStep = 0;
				this.shortStatus = SHORT_STATUS_MAP["Cancelled"];
				this.longStatus = LONG_STATUS_MAP["Cancelled"];
				this.loading = false;
				return;
			}

			if (this.trackingStatus === "delivered") {
				this.currentStep = 3;
				this.shortStatus = SIMPLE_SHORT_STATUS_MAP["Delivered"];
				this.longStatus = SIMPLE_LONG_STATUS_MAP["Delivered"];
				this.loading = false;
				return;
			}

			if (this.status === "Incoming") {
				this.currentStep = 1;
				this.shortStatus = SHORT_STATUS_MAP["Incoming"];
				this.longStatus = LONG_STATUS_MAP["Incoming"];
				this.loading = false;
				return;
			}

			if (this.status === "Additional Needed") {
				this.currentStep = 1;
				this.shortStatus = SHORT_STATUS_MAP["Additional Needed"];
				this.longStatus = LONG_STATUS_MAP["Additional Needed"];
				this.loading = false;
				return;
			}

			if (this.status === "In Progress") {
				const res = await fetch(`${BASE_URL}/order-info/${this.customerEmail}`, {
					method: "GET",
					headers: {
						"Content-Type": "application/json",
					},
				})
					.then((res) => res.json())
					.catch(() => {
						this.error = true;
						this.message = this.error_msg;
					});

				const { data } = res;

				if (!data) {
					this.currentStep = 0;
					this.error = true;
					this.message = this.error_msg;
					this.loading = false;
					return;
				}

				// Airtable Row
				this.date_shipped = data.date_shipped;
				this.date_updated = data.date_updated;

				if (haveWorkingHoursElapsed(this.date_updated, 36)) {
					// console.log("In Progress + 36");
					this.currentStep = 5;
					this.shortStatus = SHORT_STATUS_MAP["In Progress + 36"];
					this.longStatus = LONG_STATUS_MAP["In Progress + 36"];
				} else if (haveWorkingHoursElapsed(this.date_updated, 24)) {
					// console.log("In Progress + 24");
					this.currentStep = 4;
					this.shortStatus = SHORT_STATUS_MAP["In Progress + 24"];
					this.longStatus = LONG_STATUS_MAP["In Progress + 24"];
				} else if (haveWorkingHoursElapsed(this.date_updated, 12)) {
					// console.log("In Progress + 12");
					this.currentStep = 3;
					this.shortStatus = SHORT_STATUS_MAP["In Progress + 12"];
					this.longStatus = LONG_STATUS_MAP["In Progress + 12"];
				} else {
					// console.log("In Progress");
					this.currentStep = 2;
					this.shortStatus = SHORT_STATUS_MAP["In Progress"];
					this.longStatus = LONG_STATUS_MAP["In Progress"];
				}
				this.loading = false;
			}

			if (this.status === "Complete") {
				this.currentStep = 6;
				this.shortStatus = SHORT_STATUS_MAP["Shipped"];
				this.longStatus = LONG_STATUS_MAP["Shipped"];
				this.loading = false;
				return;
			}
		} catch (e) {
			console.error(e);
		}
	},
});
